import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { withTrans } from 'gatsby-theme-commons/src/i18n/withTrans'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.min.css'
import 'magnific-popup/dist/magnific-popup.css'

import './fonts/line-icons.css'
import './styles/slicknav.css'

import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import './styles/nivo-lightbox.css'

import './styles/main.css'
import './styles/responsive.css'

// window.jQuery = $;

import Header from './header'
import Copyright from './copyright'

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  return (
    <>
      <Header></Header>

      <main>{children}</main>

      <Copyright></Copyright>

      <a href="#" className="back-to-top">
        <i className="lni-arrow-up"></i>
      </a>

      <div id="preloaderx">
        <div className="loaderx" id="loader-1x"></div>
      </div>

    </>
  )
}

export default withTrans(Layout)
