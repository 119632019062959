import React, { useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'react-bootstrap'
import { func } from 'prop-types'

export const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window && window.localStorage.i18nextLng) ||
    'en'
}

const Switcher = (props) => {
  const { t, i18n } = useTranslation()
  const [ lang, setLang ] = useState(getLanguage())

  function handleClick(lang) {
    i18n.changeLanguage(lang)
  }

  function handleSelect(e) {
    setLang(e)
    i18n.changeLanguage(e)
  }

  return (
    <Dropdown size="sm" onSelect={handleSelect}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {lang}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="en">en</Dropdown.Item>
        <Dropdown.Item eventKey="id">id</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Switcher
