import React from "react"
import Layout from "./layout"

export default ({ children, pageContext }) => (
  <Layout> 
    <section className="section-padding">
    <div className="row">
        <div className="col-md-2">
          <img src={pageContext.frontmatter.image} />
          <div>
             <a href={`https://app.otomasi.id/${pageContext.frontmatter.title}/setup`}>Install</a>
           </div>
          <div>
            <a href={`https://docs.otomasi.id/${pageContext.frontmatter.title}`}>Documentation</a>
          </div>
        </div>
        <div className="col-md-10">
          {children}
        </div>
      </div>
      </section>
  </Layout>)