/* eslint-disable react/display-name */
import React, { Component } from 'react'
import i18next from './config'
import { I18nextProvider, withTranslation } from 'react-i18next'

export function withTrans(WrappedComponent) {
  WrappedComponent = withTranslation()(WrappedComponent)

  return function (props) {
    return (
        <WrappedComponent {...props} language={i18next.language} />
    )
  }
}
