/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

const React = require('react')
const { ReactKeycloakProvider } = require('@react-keycloak/web')

const { warpElement } = require('./src/i18n/warpElement')

const Keycloak = require('keycloak-js')

const keycloak = new Keycloak({
  realm: process.env.KEYCLOAK_REALM,
  url: process.env.KEYCLOAK_URL,
  clientId: process.env.KEYCLOAK_CLIENT_ID
})

const Loading = () => <div>Loading...</div>

const wrapRootElement = ({ element }) => {

  return (
    <ReactKeycloakProvider
      keycloak={keycloak}
      authClient={keycloak}
      initConfig={{
        promiseType: 'native',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
            window.location.origin + '/silent-check-sso.xhtml'
      }}
      //LoadingComponent={<Loading />}
      //isLoadingCheck={(keycloak) => !keycloak.authenticated}  
      onError={(event, error) => {
        console.log('onKeycloakError', event, error)
      }}
      onEvent={(event, error) => {
        console.log('onKeycloakEvent', event, error)
      }}
    >
      {warpElement(element)}
    </ReactKeycloakProvider>
  )
}

export { wrapRootElement }