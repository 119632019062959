import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  fallbackLng: 'en',
  resources: {
    id: {
      translations: require('../../../theme-newspaper/locale/id/Header.json')
    },
    en: {
      translations: require('../../../theme-newspaper/locale/en/Header.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    wait: true
  }
})

i18next.languages = ['id', 'en']

export default i18next
