/* eslint-disable no-unused-vars */
import React from 'react'

import { useKeycloak } from '@react-keycloak/web'
import { useTranslation } from 'react-i18next'

const UserNav = () => {
  const { keycloak, initialized } = useKeycloak()
  const { t } = useTranslation()

  return (
    <div className="login d-flex">
      {keycloak.authenticated && (
        <button type="button" onClick={() => keycloak.logout()}>
          {t('Logout')}
        </button>
      )}
    </div>
  )
}

export default UserNav
