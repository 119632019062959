import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import Login from 'gatsby-theme-commons/src/components/Login'
import Switcher from 'gatsby-theme-commons/src/i18n/Switcher'
import { withTrans } from 'gatsby-theme-commons/src/i18n/withTrans'

const link = (node) => {
  var active = node.active ? ' active' : ''
  var dropdown = node.childs && node.childs.length > 0 ? ' drowdown' : ''
  var anchor = dropdown === '' ? (
    <a className="nav-link" href={node.url}>
      {node.title}
    </a>
  ) : (
    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {node.title}
    </a>
  )
  var subs = ''

  if (dropdown != '') {
    subs = <div className="dropdown-menu" aria-labelledby="navbarDropdown">
      { node.childs.map(c => <a key={'support_' + c.title} className="dropdown-item" href={c.url}>{c.title}</a>)}
    </div>
  }

  return <li key={'nav_' + node.title.replace(' ', '')} className={'nav-item' + active + dropdown}>
    {anchor}
    {subs}
  </li>
}

const Navbar = () => {
  const data = useStaticQuery(graphql`
  query StackPrimaryQuery {
    allPrimaryJson {
      edges {
        node {
          title
          visible
          url
          active
          childs {
            title
            visible
            url
          }
        }
      }
    }
  }
`)

  return (
    <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
      <Login></Login>
      <Switcher></Switcher>
      <div className="container">
        <a href="index.html" className="navbar-brand"><img src="/img/logo1.png" alt=""/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <i className="lni-menu"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
            {data.allPrimaryJson.edges.map(e => link(e.node))}
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default Navbar
