/* eslint-disable react/display-name */
import React from 'react'
import WeatherIcon from 'react-icons-weather'
import ReactAnimatedWeather from 'react-animated-weather'

const defaults = {
  icon: 'CLEAR_DAY',
  color: 'goldenrod',
  size: 512,
  animate: true
}

export default () => (<div>
  Weather
  <div>
    <WeatherIcon name="owm" iconId="200" flip="horizontal" rotate="90" />
    <ReactAnimatedWeather
      icon={defaults.icon}
      color={defaults.color}
      size={defaults.size}
      animate={defaults.animate}
    />
  </div>
</div>)
