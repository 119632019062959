/* eslint-disable react/display-name */
import React, { Component } from 'react'
import i18next from './config'
import { I18nextProvider } from 'react-i18next'

export function warpElement(element) {
    return (
      <I18nextProvider i18n={i18next}>
        { element }
      </I18nextProvider>
    )
}
