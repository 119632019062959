/* eslint-disable no-unused-vars */
import React from 'react'

import { useKeycloak } from '@react-keycloak/web'
import { useTranslation } from 'react-i18next'

import UserNav from './UserNav'

function Login() {
  const { keycloak, initialized } = useKeycloak()
  const { t } = useTranslation()

  return (
    <div className="login d-flex">
      {keycloak.authenticated ? (
        <UserNav></UserNav>
      ) : (
        <a href="#" onClick={() => keycloak.login()}>{t('Login')}</a>
      )}
    </div>
  )
}

export default Login
