import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import CopyrightItem from './components/Copyright'

const Copyright = () => {
  const data = useStaticQuery(graphql`
  query StackCopyrightQuery {
    allCopyrightJson {
      edges {
        node {
          social {
            facebook
            twitter
            instagram
            linkedin
         }
        }
      }
    }
  }
`)

  return (
    <div>
      {data.allCopyrightJson.edges.map((e, i) => { return <CopyrightItem key={`xcopy_${i}`} node={e.node}></CopyrightItem> })}
    </div>
  )
}

export default Copyright
