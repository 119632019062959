import React from 'react'

import Navbar from './navbar'

const Header = () => (
  <header id="header-wrap">
    <Navbar></Navbar>
  </header>
)

export default Header
