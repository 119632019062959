import React from 'react'
import PropTypes from 'prop-types'

class Copyright extends React.PureComponent {
  render() {
    const { node } = this.props

    return <div key="copy_1" className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-3 col-xs-12">
            <div className="footer-logo">
              <img src="/img/logo1.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="social-icon text-center">
              <a className="facebook" href={node.facebook}><i className="lni-facebook-filled"></i></a>
              <a className="twitter" href={node.twitter}><i className="lni-twitter-filled"></i></a>
              <a className="instagram" href={node.instagram}><i className="lni-instagram-filled"></i></a>
              <a className="linkedin" href={node.linkedin}><i className="lni-linkedin-filled"></i></a>
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-xs-12">
            <p className="float-right">Copyright 2019 - <a href="https://otomasi.id" rel="nofollow">Otomasi</a></p>
          </div>
        </div>
      </div>
    </div>
  }
}

Copyright.propTypes = {
  node: PropTypes.any
}

export default Copyright
